.main-footer-container{
    margin: auto;
    background-color: #fff;
    color: #0D2330;
}
.main-footer-section-1{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 35% 30% 35%;
    text-align: center;
    justify-content: center;
    margin-top: 50px;
}
.main-footer-section-1 img{
    height:28px;
    width: 137px;   
}
.main-footer-section-1 p,
.main-footer-section-1 h6{
    text-align: left;
}
.main-footer-section-1 .section-1-grid-items{
    display: flex; 
    flex-direction: row; 
    align-items: center;
    margin-bottom: 10px;
}
.main-footer-section-1 .section-1-grid-items img{
    height:22px;
    width:22px;
    margin-bottom: 25px;
    margin-right: 10px;   
}
.main-footer-section-1 .section-1-grid-items .phone-icon{
    margin-bottom: 5px;  
}
.main-footer-section-1 .main-footer-icons img{
/* margin-left: 15px; */
/* margin-top: 15px; */
height:44px;
width:44px;
}
.main-footer-section-1 .email-link{
    color: #3cf;
}


/* .main-footer-section-1 .main-footer-subsection{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 2; 
    align-items: center;
}
.main-footer-section-1 .main-footer-subsection .loc-icon{
    height:30px;
    width:30px;    
}
.grid-item-footer{
    display: flex; 
    flex-direction: row; 
    align-items: center;  
   
} */