.privacy-policy-container{
    background-color:#F7F9FB;
    /* background-color:#ECFAFF; */
    display: grid;
    grid-template-columns: 20% 60% 20%;
    text-align: center;
    justify-content: center;
    margin: auto;
}
.privacy-policy-container .privacy-policy-content{
margin-top: 30px;
margin-bottom: 50px;
}
.privacy-policy-content h1{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;  
}
.privacy-policy-content .horizontal-line {
    margin-top: 20px;
    width: 100%;
    height: 1.5px !important;
    background-color: #c2b6b6;
}
.privacy-policy-content h2{
    text-align: justify;   
}
.privacy-policy-content p{
    margin-top: 30px;
    text-align: justify;
}
.privacy-policy-content ol {
    text-align: justify;
}
.privacy-policy-content span{
    font-weight: bold; 
}