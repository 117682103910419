:root { 
    --green_100:#c5e8bc; 
    --red_600:#f72d2d; 
    --light_green_A700:#21c512; 
    --gray_800_01:#4b4a4a; 
    --red_400:#cd4c4c; 
    --green_500:#32c841; 
    --light_blue_900:#0556a1; 
    --teal_100:#b8dfe2; 
    --blue_gray_500_01:#4776a1; 
    --yellow_800:#e49a2a; 
    --gray_200_01:#f0f0f0; 
    --black_900_07:#00000007; 
    --gray_200_02:#eae8e8; 
    --blue_gray_900:#213339; 
    --cyan_A700:#19aecf; 
    --yellow_800_01:#e49a2b; 
    --pink_100:#ecb0d1; 
    --black_900_05:#00000005; 
    --deep_orange_100:#fbbebe; 
    --gray_200_03:#ebe6e6; 
    --green_100_01:#c9f5c3; 
    --gray_200_04:#f2f1f1; 
    --gray_600:#767373; 
    --light_blue_900_01:#0455a1; 
    --blue_gray_100:#d4d4d4; 
    --blue_gray_300:#919cc2; 
    --gray_800:#363c3e; 
    --blue_gray_500:#4776a2; 
    --gray_200:#eaeaea; 
    --black_900_0c:#0000000c; 
    --indigo_200:#82add5; 
    --blue_100:#c0ecfa; 
    --white_A700:#ffffff; 
    --black_900_16:#00000016; 
    --light_blue_A200:#33ccff; 
    --red_500:#f73434; 
    --green_600:#3bb939; 
    --gray_50:#f8fdff; 
    --black_900_21:#00000021; 
    --black_900_23:#00000023; 
    --black_900:#000000; 
    --gray_500_01:#909090; 
    --gray_500_03:#acabab; 
    --gray_500_02:#aea4a4; 
    --gray_700:#67696a; 
    --gray_500:#a5a4a4; 
    --gray_600_01:#858585; 
    --blue_gray_400:#878d8f; 
    --blue_800:#2866c2; 
    --gray_900:#252525; 
    --blue_600:#21a0ca; 
    --light_blue_50:#dff7ff; 
    --teal_50:#d2ecf5; 
    --gray_300:#e0dbdb; 
    --gray_300_02:#dfdddd; 
    --gray_300_01:#e1e1e1; 
    --gray_100:#f4f4f4; 
    --orange_100:#f2e2aa; 
    --light_blue_50_01:#ddf5fd; 
    --black_900_35:#00000035; 
    --blue_100_00:#c0ecfa00; 
    --light_blue_50_02:#ebfaff; 
}