/*  Body  Start  */

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: black;
}

.link-class {
  text-decoration: none !important;
}

.btn {
  background: #33ccff !important;
  color: #fff !important;
  cursor: pointer;
  font-family: Inter;
  border: none;
  border-radius: 4px;
}

.scroll-body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  scroll-behavior: smooth;
}

.scrolling-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: auto;
  scroll-snap-type: y mandatory;
  /* scroll-snap-type: y proximity; */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.scrolling-container .section {
  position: relative;
  width: 100%;
  height: 100vh;
  background-blend-mode: multiply;
}

/* .scrolling-container .section:nth-child(4){
content: "";
position: relative;
top: 0;
right: 0;
bottom: 0;
left: 0;
background-size: cover;
z-index: -1;
opacity: 1;
background-image: url('../../public/images/BackgroundMain.png'); 
} */

.scrolling-container .section:nth-child(1) {
  scroll-snap-align: start;
}

.scrolling-container .section:nth-child(2) {
  scroll-snap-align: center;
  /* background-color: #213339; */
  background-color: #ffffff;
  /* color: #fff; */
}
.scrolling-container .section:nth-child(3) {
  scroll-snap-align: center;
  background-color: #0e3748;
  color: #fff;
}
.scrolling-container .section:nth-child(4) {
  scroll-snap-align: center;
  background-color: #E0F8FF;
}

.scrolling-container .section:nth-child(5) {
  scroll-snap-align: center;
  background-color: #ffffff;
  /* background-color: #213339; */
  /* background-color: #0E3748; */
}
.scrolling-container .section:nth-child(6) {
  scroll-snap-align: center;
  background-color: #ffffff;
  color: #fff;
}
.scrolling-container .section:nth-child(7) {
  scroll-snap-align: center;
  background-color: #ffffff;
}

.scrolling-container .section:nth-child(8) {
  scroll-snap-align: center;
  background-color: #ffffff;
}
.scrolling-container .section:nth-child(9) {
  scroll-snap-align: center;
  background-color: #ffffff;
}

.scrolling-container .section:nth-child(10) {
  scroll-snap-align: center;
  background-color: #ffffff;
}
.scrolling-container .section:nth-child(11) {
  scroll-snap-align: center;
  background-color: #ffffff;
}

/*  Body  End  */

/*  Header  Start  */

.header-nav {
  width: 100%;
  height: 71px;
}

.header-nav .header-container {
  padding: 20px 85px !important;
  display: flex;
  justify-content: space-between;
}

.header-nav .header-container .header-section:nth-child(1) {
  display: flex;
}

.header-nav .header-container .logo img {
  height: 28px;
}

.header-nav .header-container ul {
  padding-left: 0;
  margin-bottom: 0;
  display: flex !important;
  font-family: Inter;
  margin-left: 57px;
  margin-top: 0px;
}

.header-nav .header-container ul li {
  padding: 0 25px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}

.header-nav .header-container ul li a {
  text-decoration: none;
  color: #252525;
}

.header-nav .header-container ul li::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #33ccff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.header-nav .header-container ul li:hover::before {
  transform: scaleX(1);
}

.header-nav .header-container .header-btn {
  width: 180px;
  height: 40px;
  font-size: 14px;
}
.header-nav .header-container {
  display: flex;
  justify-content: space-between; /* Ensures space between logo and buttons */
  align-items: center; /* Centers items vertically */
  padding: 0 20px; /* Adjust padding as needed */
}

.header-nav .header-section {
  display: flex;
  align-items: center;
}

.header-container .logo {
  flex-grow: 1; /* Allows the logo section to take up necessary space */
}

.header-container .header-section:not(.logo) {
  flex-grow: 0; /* Prevents the button sections from growing */
  padding: 0 10px; /* Adds padding between the buttons */
}

.header-nav .header-container {
  display: flex;
  justify-content: space-between; /* Aligns children (logo and buttons) on opposite ends */
  align-items: center; /* Vertically centers the content */
  padding: 0 20px; /* Padding on the sides of the container */
  width: 100%; /* Ensures the container takes full width */
}

.header-nav .header-section {
  display: flex;
  align-items: center; /* Centers items vertically within each section */
}

.header-container .logo {
  margin-right: auto; /* Pushes all other elements to the right */
}

.header-section:last-child {
  display: flex;
  gap: 20px; /* Increase space between the two buttons */
}

.btn {
  width: 150px; /* Set a specific width for each button */
  padding: 10px 0; /* Uniform padding for better button size management */
  text-align: center; /* Center the text inside the button */
}


/*  Header  End  */

/*  Home  Start  */

.banner {
  /* height: 70vh !important; */
  overflow-y: hidden !important;
}

.home .home-container {
  padding: 0 148px !important;
}

.home .home-container .left-panel {
  float: left;
  display: block;
  font-family: Poppins;
  position: absolute;
  text-align: justify;
  top: 37%;
  left: 18%;
  transform: translate(-50%, -25%);
}
.home .home-container .right-panel {
  float: right;
  display: block;
  font-family: Poppins;
  position: absolute;
  top: 30%;
  right: 25%;
  transform: translate(50%, -25%);
}
.home .home-container .right-panel .right-panel-element {
  width: 770px;
  height: 650px;
}
.home .home-container .right-panel-qrcode .qrcode-container {
  display: flex;
  background-color: var(--white);
  width: 275px;
  height: 113;
  padding: 10px;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.13);
  position: absolute;
  right: 15%;
  bottom: 6%;
  transform: translate(50%, -25%);
  border-radius: 9px;
}
.home .home-container .right-panel-qrcode .qrcode-container .qr-text {
  font-family: "Poppins", sans-serif;
  width: 40%;
  text-align: left;
  font-weight: 700;
  line-height: 25px;
  margin-top: 5px;
}

.home .home-container .right-panel-qrcode .qrcode-container .qr-code {
  margin-left: auto;
  width: 89px;
  height: 89px;
}
/* .home .home-container .right-panel-qrcode{
  position: absolute;
  right: 10%;
  bottom: 8%;
  transform: translate(50%, -25%);
  width: 230px;
  background-color: var(--white);
  padding: 10px;
  box-shadow: 0 4px 9px 0px rgba(0, 0, 0, 0.43);
  border-radius: 9px;

}
.home .home-container .right-panel-qrcode .qrcode-container{
  display: flex;
} */

.home .home-container .left-panel h2,
.home .home-container .left-panel p {
  margin-bottom: 25px;
  color: #252525;
}

.home .home-container .left-panel img {
  height: 115px;
  width: 255px;
}

.home .home-container .left-panel h2 {
  font-size: 64px;
  line-height: 70px;
  font-weight: 700;
  margin-top: 25px;
}

.home .home-container .left-panel p {
  font-size: 16px;
  line-height: 29px;
  font-weight: 400;
}

.home .home-container .left-panel .home-btn {
  width: 203px;
  height: 52px;
  padding: 11px 32.63px 9px 32px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home .home-container .left-panel .img-googleplay {
  width: 182px;
  height: 54px;
  margin-left:25px;
}

/*  Home  End  */

/* Partners Start */

/* eprise text page........ */

/* .Eprise-text-container{
  display: flex ;
  justify-content: center;
  align-items: center; 
} */

/* ........................................ */

.content_cat_partners_1 .grid-text-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  justify-content: center;
  margin: auto;
  
}
.content_cat_partners_1 .grid-text-container .left-grid-section,
.content_cat_partners_1 .grid-text-container .right-grid-section{
  transition: transform 1s ease; 
}
.shrunken-left {
  transform: translate(75%, 0%) scale(0);
}
.shrunken-right {
  transform: translate(-75%, 0%) scale(0);
}

.content_cat_partners_1 .grid-text-container .etext-section p{
  font-size: 18px;
  color: #cfcece;
  font-weight: 500; 
}
.content_cat_partners_1 .grid-text-container .etext-section #blinkset1{
  font-size: 18px;
  font-weight: 500; 
  animation: colorChange 5s forwards infinite;
  /* transition: transform 0.3s ease;  */
}
.content_cat_partners_1 .grid-text-container .etext-section #blinkset2{
  font-size: 18px;
  font-weight: 500; 
  animation: colorChange 10s forwards infinite;
  
}
.content_cat_partners_1 .grid-text-container .etext-section #blinkset3{
  font-size: 18px;
  font-weight: 500; 
  animation: colorChange 35s forwards infinite;
  
}

@keyframes colorChange {
  0% {
    color: #cfcece;
    font-weight: 500;
    }
  50%{
    color: #cfcece;
    font-weight: 500;
    

  }
  100% {
    color: #252525;
    font-weight: 500;
    /* transform: scale(1.2); */
  }
}
/* .content_cat_partners_1 .grid-text-container .etext-section p:hover{
  color: #252525;
} */

.content_cat_partners_1 .grid-text-container .left-grid-section,
.content_cat_partners_1 .grid-text-container .right-grid-section{
  margin: 50% auto;
}

.content_cat_partners_1 .grid-text-container .middle-grid-container{
  margin: auto;
}

.content_cat_partners_1 .grid-text-container .middle-grid-image{
  width: 360px;
  height: 600px;
  cursor: pointer;
}

.grid-col-3 .first-child,
.grid-col-2 .first-child{
  text-align: left;
}
.grid-col-3 .last-child,
.grid-col-2 .last-child{
  text-align: right;
}
.grid-col-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  margin-bottom: 30px;
}

.grid-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  margin-bottom: 30px;
 }

.content_cat_partners_1 .div-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Eprise-text-container {
  width: 90% !important;
  display: block;
  font-size: 20px;
  font-family: Inter;
  color: #061561;
  padding: 0 15px;
  transition: transform 1s ease;
  position: relative;
  margin: auto;
}

.features_1 h2,
.content_cat_partners_1 h2 {
  padding: 10px 0 !important;
  /* cursor: pointer; */
}
.content_cat_partners_1 .texture-logo-image {
  width: 355px;
  height: 250px;
  margin: auto;
  display: block;
  text-align: center;
  z-index: 1;
  display: flex;
  align-content: center;
  cursor: pointer;
  position: absolute;
  transition: transform 12s ease;
  transform: translateY(0%);
  /* opacity: 0; */

  /* top: 100%; 
  opacity: 0; 
  transition: top 1s ease, opacity 0.5s ease; 
  visibility: hidden;  */
}
.texture-logo-image.slide-up {
  transform: translateY(0%);
  opacity: 1;

  /* 
  top: 50%;
  opacity: 1; 
  visibility: visible; */
}


.Eprise-text-container .etext-section p {
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-bottom: 0;
}

.Eprise-text-container .etext-section p:hover {
  transform: scale(1.1);
}

.Eprise-text-container .text-bold {
  color: #061561;
  font-weight: 700;
  /* color:#ffffff; */
}

.grid-col-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 40px;
  text-align: center;
}
/* .grid-col-5 .first-child,
.grid-col-4 .first-child,
.grid-col-3 .first-child,
.grid-col-2 .first-child {
  text-align: left;
}

.grid-col-5 .last-child,
.grid-col-4 .last-child,
.grid-col-3 .last-child,
.grid-col-1 .last-child {
  text-align: right;
} */

.grid-col-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  margin-bottom: 40px;
}



.grid-col-1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 20px 0;
  height: 150px;
}

/* .Eprise-text-container .etext-section img{
  width: 375px;
  height: 150px;
  text-align: center;
  margin: auto;
  display: block;
} */

.Eprise-text-container .etext-section p:hover {
  background-image: linear-gradient(
    -225deg,
    #429bd8 0%,
    #5fc3ef 29%,
    #a6ccd6 67%,
    #f7d5b5 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1s linear infinite;
  display: inline-block;
  font-size: 20px;
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* ......................................................... */
/* .Eprise-text-container .row{
  display: flex;
  margin-bottom: 35px;
}
.Eprise-text-container .row .col-12{
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 70px;
}
.Eprise-text-container .row .col-12 .horizontal-row{
  justify-content: space-between;
}
.Eprise-text-container .row .col-12 .texture-main-logo{
  width:40%;
}
.Eprise-text-container .row .col-12 .left-texture{
   display: inline-block; 
   display:block;
  align-items: center; 
   justify-content: space-around; 
  column-gap: 70px; 
}

.Eprise-text-container .row .col-12 .right-texture{
  align-content:right;
}
.Eprise-text-container .row .col-4 .E-text{
  margin-top: 60px;
}
.Eprise-text-container .E-text{
  display: flex;
  transition: transform 0.3s ease;  
}
.Eprise-text-container .E-text:hover{
  transform: scale(1.5);
  cursor: pointer;
}


.Eprise-text-container .E-text h6{
  margin:0 !important;
  padding:0;
  font-size:30px;
  color:#061561
  position:relative;
}

  .Eprise-text-container .E-text h6:hover:before{
    width:100%;
  }

.Eprise-text-container .E-text {
  white-space: pre; 
  display: inline-block; 
}

.Eprise-text-container img{
  width:500px;
  height:200px;
}
.Eprise-text-container h6:hover{
  
  background-image: linear-gradient(
    -225deg,
    #429BD8 0%,
    #5FC3EF 29%,
    #A6CCD6 67%,
    #F7D5B5 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3s linear infinite;
  display: inline-block;
      font-size: 30px;
}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
} */

/* .Eprise-text-container h6 {
  
  display: inline-block;
  animation: squiggle 6.0s infinite linear;
  transform-origin: 50% 50%;
}

@keyframes squiggle {
  0% {
    transform: translateX(10) rotate(0);
  }
  25% {
    transform: translateX(5px) rotate(5deg);
  }
  50% {
    transform: translateX(0) rotate(0);
  }
  75% {
    transform: translateX(-10px) rotate(-5deg);
  }
  100% {
    transform: translateX(0) rotate(0);
  }
} */

/* .Eprise-text-container h6 {
  
  
  display: inline-block;
  animation: zigzag 2s infinite linear;
}

@keyframes zigzag {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
} */

/* eprise text page ends */

.content_cat_partners_1,
.content_cat_partners_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
}

.content_cat_partners_1 {
  flex-direction: column;
}
.content_cat_partners_1 h2 {
  margin: 30px auto !important;
}

.content_cat_partners_1 h2,
.content_cat_partners_2 h2 {
  display: block;
  margin: auto;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 90.5px;
  padding: 70px 0;
}

.content_cat_partners_2,
.features_2 {
  flex-direction: column;
  overflow: hidden;
  transition: height 2s ease;
}

.content_cat_partners_2 .centered,
.features_2 .centered {
  opacity: 1;
  transform: translateY(50%);
  transition: opacity 2s ease, transform 2s ease;
}

.content_cat_partners_2 h2 {
  padding: 10px 0;
}

/* flip animation  */
/* .bills {
  position: relative;
  display: inline-block;
  padding: 0;    
  transition: transform 0.3s;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
}

.bills:before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  content: attr(data-hover);
  transition: color 0.3s;
  transform: rotateX(-90deg);
  transform-origin: 50% 0;
  text-align: center;
}

&:hover span,
&:focus span {
  transform: rotateX(90deg) translateY(-22px);
} */

/* &:hover .bills:before,
&:focus .bills:before {
  color: rgb(210, 73, 54);
} */


.content_cat_partners_2 .cat_partners_2_row {
  column-gap: 30px;
}

.content_cat_partners_2 .cat_partners_2_row,
.features_2 .slider-feature {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(30%);
  transition: transform 2s ease;
}

.content_cat_partners_2 .partner-slider {
  height: 225px;
  width: 100%;
  background-color: #fff;
  transform: translateY(0);
  transition: transform 2s ease;
}
.content_cat_partners_2 .partner-slider {
  padding-left: 40px;
  padding-right: 40px;
}

.content_cat_partners_2 .partner-slider img {
  height: 260px;
}

.content_cat_partners_2 .cat_partners_2_row .card {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #426470;
  padding: 40px !important;
  border-radius: 30px !important;
  height: auto;
  column-gap: 15px;
}

.content_cat_partners_2 .cat_partners_2_row .card img {
  height: 80px !important;
  width: 80px !important;
  text-align: center;
  margin: auto;
}

.content_cat_partners_2 .cat_partners_2_row .card p {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  text-align: center;
  color: #fff;
}

/* CSS for sliding up animation */
.content_cat_partners_2.animate .centered,
.features_2.animate .centered {
  transform: translateY(-50%);
}

.content_cat_partners_2.animate .cat_partners_2_row {
  transform: translateY(-35%);
}

.features_2.animate .slider-feature {
  transform: translateY(-15%);
}

/* Partners End */

/* Animation */

/* Define the initial state of the element */
.slide-up-element {
  opacity: 0; /* Start the element invisible */
  transform: translateY(50%); /* Move the element down 100% of its height */
  animation: slideUpAnimation 1s ease forwards; /* Apply the slide-up animation on page load */
}

/* Define the animation using keyframes */
@keyframes slideUpAnimation {
  to {
    opacity: 1;
    transform: translateY(
      0
    ); /* Slide the element up to its original position */
  }
}

.logos-marquee-slider {
  display: flex;
}

/*  Feature 1 */

.features_1,
.features_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
}

.features_1 .left-girl {
  position: absolute;
  top: 60%;
  right: 20%;
  /* transform: translate(50%, -30%); */
  transform: translate3d(50%, -30%, 0);
  animation: slideInOutLeft 8s ease-in-out infinite;
  backface-visibility: hidden;
  will-change: transform;
}
@keyframes slideInOutLeft {
  0%,
  100% {
    transform: translate3d(
      110%,
      -30%,
      0
    ); /* Use translate3d for hardware acceleration */
  }
  50% {
    transform: translate3d(
      30%,
      -30%,
      0
    ); /* Use translate3d for hardware acceleration */
  }
  70% {
    transform: translate3d(
      20%,
      -30%,
      0
    ); /* Use translate3d for hardware acceleration */
  }
}
/* @keyframes slideInOutLeft {
  0%, 100% {
    transform: translate(110%, -30%);
  }
  50% {
    transform: translate(30%, -30%);
  }
  70% {
    transform: translate(20%, -30%);
  }
} */

.features_1 .right-girl {
  position: absolute;
  top: 46%;
  left: 8%;
  transform: translate3d(-30%, 3%, 0);
  /* transform: translate(-30%, 3%); */
  animation: slideInOutRight 8s ease-in-out infinite;
  backface-visibility: hidden;
  will-change: transform;
}
@keyframes slideInOutRight {
  0%,
  100% {
    transform: translate3d(
      -100%,
      3%,
      0
    ); /* Use translate3d for hardware acceleration */
  }
  50% {
    transform: translate3d(
      6%,
      3%,
      0
    ); /* Use translate3d for hardware acceleration */
  }
  70% {
    transform: translate3d(
      10%,
      3%,
      0
    ); /* Use translate3d for hardware acceleration */
  }
}
/* @keyframes slideInOutRight {
  0%, 100% {
    transform: translate(-100%, 3%);
  }
  50% {
    transform: translate(6%, 3%);
  }
  70% {
    transform: translate(10%, 3%);
  }
} */
.features_1 .right-girl {
  height: 500px;
  width: 500px;
}
.features_1 .left-girl {
  height: 500px;
  width: 700px;
}

/* .features_1 .slide-in-rightanimation{
  position: absolute;
   
  animation-name: slideInLeft;
  animation-duration: 5.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slideInLeft {
  0% {
    left: -100%; 
  }
  100% {
    left: 15%; 
  }
} */

/* ....................................................................... */
/* .features_1 .slide-in-out-right-girl-animation {
  position: absolute;
  left: -100%; 
  animation: slideInOutRight 8s ease-in-out infinite; 
} */

/* @keyframes slideInOutRight {
  0% {
    left: -100%; 
  }
  50% {
    left: 15%; 
  }
  70%{
    left:20%;
  }
  90%{
    left:-100%;
  }
  99%{
    left:-100%;
  }
  100% {
    left: -100%; 
  }
} */
/* ........................................................................ */

/* .features_1 .slide-in-out-left-girl-animation {
  position: absolute;
  right: -100%; 
  animation: slideInOutLeft 8s ease-in-out infinite;

}
@keyframes slideInOutLeft {
  0% {
    right: -100%; 
  }
  50% {
    right: 20%; 
  }
  70%{
    right:25%;
  }
  90%{
    right:-100%;
  }
  99%{
    right:-100%;
  }
  100% {
    right: -100%; 
  }
} */
/* .............................................................................. */

/* .features_1 .slide-in-leftanimation{
  position: absolute;
   
  animation-name: slideInRight;
  animation-duration: 5.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slideInRight {
  0% {
    right: -100%; 
  }
  100% {
    right: 20%; 
  }
} */

/* ................................ */
.features_1 h2,
.features_2 h2 {
  display: block;
  margin: auto;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 90.5px;
  padding: 70px 0;
}

.features_2 h2 {
  padding: 10px 0;
  /* background-color: burlywood; */
}

/* .features_2 .slider-feature .carousel-inner{
    width: 1084px;
} */

.features_2 .slider-feature {
  width: 1550px;
  height: 600px;
 
  border-radius: 30px;
  
  /* background-color: aqua; */
  padding: 10px;
}

/* .features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .col-6 {
  background-color: #000;
} */

.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-1 {
  padding: 50px;
  background-color: #f6e5b1;
  height: 520px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  
  
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-2 {
  padding: 50px;
  background-color: #d1f1c8;
  height: 520px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-3 {
  padding: 50px;
  background-color: #f7c2e3;
  height: 520px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-4 {
  padding: 50px;
  background-color: #dce3f8;
  height: 520px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-5 {
  padding: 50px;
  background-color: #b9e0e2;
  height: 520px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-6 {
  padding: 50px;
  background-color: #faddff;
  height: 520px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-7 {
  padding: 50px;
  background-color: #afd4f8;
  height: 520px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-8 {
  padding: 50px;
  background-color: #F4DACA;
  height: 520px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-9 {
  padding: 50px;
  background-color: #caefc9;
  height: 520px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}

.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .text1 {
  font-family: "Poppins", sans-serif;
  font-size: 40px !important;
  font-weight: 600;
  color: #373d3f;
  text-align: left;
  font-style: normal;
  line-height: 69.5px;
  padding: 10px;
  /* background-color: #d3f2fd; */
}
/* slides images height-width */
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-images {
  /* background-color: cornflowerblue; */
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-1
  img {
  width: 440px;
  height: 380px;
  margin: auto;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-2
  img {
  width: 480px;
  height: 380px;
  margin: auto;
  /* background-color: #000; */
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-3
  img {
  width: 440px;
  height: 380px;
  margin: auto;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-4
  img {
  /* background-color: #061561; */
  width: 440px;
  height: 380px;
  margin: auto;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-5
  img {
  width: 340px;
  height: 380px;
  margin: auto;
  /* background-color: #000; */
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-6
  img {
  width: 320px;
  height: 380px;
  margin: auto;
  /* background-color: #000; */
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-7
  img {
  width: 320px;
  height: 380px;
  margin: auto;
  /* background-color: #000; */
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-8
  img {
  width: 360px;
  height: 380px;
  margin: auto;
  /* background-color: #000; */
}

.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-9
  img {
  /* background-color: #da8686; */
  width: 360px;
  height: 380px;
}

.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .text2 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #67696a;
  text-align: left;
  font-style: normal;
  line-height: 28.5px;
  padding: 10px;
  margin-bottom: 0;
  /* background-color: brown; */
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-header-text {
  padding: 10px;
  /* background-color: #373d3f; */
}

/* expanding feature starts here */

.expanding-features {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
}

.expanding-features h2 {
  display: block;
  margin: auto;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 90.5px;
  padding: 70px 0;
}

/*  Blog Start  */

.blog,
.faq,
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
}

.blog .blog-container {
  width: 60%;
  padding: 20px;

  /* background-color: #929dc2; */
}

.blog .blog-top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  column-gap: 550px;
  height: 150px;
  padding: 20px;
  width: max-content;
  /* background-color: #c0ecfb; */
}

.blog .blog-top h2,
.faq .faq-h2 {
  display: block;
  text-align: left;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 90.5px;
  padding: 70px 0;
}

.blog .blog-btn {
  width: 140px;
  height: 60px;
  padding: 10px;
  margin: auto;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 73px;
  font-size: 14px;
  float: right !important;
  /* align-items: end; */
}

.blog .blog-body p {
  font-family: Poppins;
  font-size: 16px;
  color: #67696a;
  padding: 20px;
}

.blog .blog-body .blog-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 40px;
  row-gap: 20px;
  margin-top: 15px;
}

.blog .blog-body .blog-grid .blog-content {
  background: #c0ecfb;
  margin: auto;
  border-radius: 17px;
  height: 194px;
  width: 313px;
}

/*  Blog End  */

/* download section starts */
.download-page {
  display: block;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
}

.download-page .download-header {
  /* background-color: #fff; */
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 115px;
  /* background-color: #929dc2; */
}
.download-page .download-header .download-marquee {
  width: 85%;
}
.download-page .download-header p {
  color: #373d3f;
  text-align: center;
  font-family: Poppins;
  font-size: 49px;
  font-style: normal;
  font-weight: 700;
  line-height: 69.5px;
}
.download-page .download-content {
  background-color: #dff7ff;
  height: 430px;
  display: flex;
  justify-content: space-evenly;
  /* background-color: #426470; */
}

.download-page .download-footer {
  height: 100px;
  background-color: #dff7ff;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  padding-left: 140px;
  padding-right: 140px;
}
.download-page .download-footer p {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  text-align: justify;
}

.download-page .download-content .card {
  display: flex;
  width: 450px;
  height: 350px;
  /* padding: 305px 30px 18px 30px; */
  align-items: center;
  margin-top: 30px;
  padding: 10px;
}
.download-page .download-content .card img,
.download-page .download-content .card .img-empty {
  width: 400px;
  height: 284px;
}
.download-page .download-content .card .card-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.download-page .download-content .card .card-body p {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.download-page .download-content .card .card-body .btn {
  display: flex;
  width: 114px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

/* download section ends */

/*  FAQ Start  */

.faq {
  flex-direction: column;
}

.faq .faq-body {
  width: 60%;
}

/*  FAQ End  */

/*  Footer1 Start  */

.footer {
  flex-direction: column;
}

.footer .footer-top {
  align-items: center;
  justify-content: center;
  height: 500px !important;
  /* height: 300px !important; */
  background: #ecfaff;
  width: 100%;
}

.footer .footer-top .row {
  width: 95%;
  margin: auto;
  align-items: center;
  height: 500px !important;
  /* background-color: #061561; */
}

/* .footer .footer-top .row .col-md-6{


} */

.footer .footer-top .row .col-md-6 h2 {
  font-family: Poppins;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 55.5px;
  color: #213339;
}

.footer .footer-top .row .col-md-6 h2 span {
  color: #33ccff;
}

.footer .footer-top .row .col-md-6 p {
  color: #67696a;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.5px;
}

.footer .footer-top .row .col-md-6 .img-googleplay {
  width: 182px;
  height: 54px;
}
.footer .footer-top .row .col-md-6:nth-child(2) {
  display: flex;
}

.footer .footer-top .row .col-md-6:nth-child(2) img.img-phone-footer {
  width: 180px;
  height: 340px;
}
.footer .footer-top .row .col-md-6:nth-child(2) img.img-laptop-footer {
  width: 450px;
  height: 360px;
}

/*  Footer1 End  */

/* footer2 starts */
.footer-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
  /* background-color: #0e7394; */
}

.footer .footer-bottom {
  height: 566px !important;
  /* background-color: #929dc2; */
  background-color: #fff;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  width: 100%;
  padding: 60px;
}
.footer .footer-bottom .row {
  display: flex;
  justify-content: space-between;
  /* background-color: #426470; */
  height: max-content;
}
/* .footer-2 .footer-bottom .row:nth-child(1){
  margin-bottom: 30px;
} */
.footer .footer-bottom .row:nth-child(1) .col-md-6 p {
  color: #67696a;
  text-align: justify;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 0;
  line-height: 32px;
  padding: 80px;
}
.footer .footer-bottom .row:nth-child(1) .col-md-2 h5,
.footer .footer-bottom .row:nth-child(1) .col-md-5 h5,
.footer .footer-bottom .row:nth-child(1) .col-md-5 h6 {
  color: #67696a;
  /* text-align: center; */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.footer .footer-bottom .row:nth-child(1) .col-md-2 h6 {
  color: #67696a;
  /* text-align: center; */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.footer .footer-bottom .row:nth-child(1) .col-md-5 p {
  color: #67696a;
  text-align: justify;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.footer .footer-bottom .row:nth-child(1) .col-md-5 .row-numbers {
  display: flex;
  align-items: center;
  /* justify-content:center; */
  /* text-align: end; */
}
.footer .footer-bottom .row:nth-child(1) .col-md-5 .row-p-tage {
  display: flex;
}
.footer .footer-bottom .row:nth-child(1) .col-md-5 .row-numbers h6 {
  padding: 5px 0px;
  margin-right:10px;
  margin-bottom: 0px;
}
.footer .footer-bottom .row:nth-child(1) .col-md-5 .row-numbers h6 a{
  color: #67696a;
  text-decoration: none;
}
.footer .footer-bottom .row:nth-child(1) .col-md-5 .row-numbers img,
.footer .footer-bottom .row:nth-child(1) .col-md-5 .row-p-tage img {
  height: 33px;
  width: 33px;
  padding: 5px;
  margin-right: 10px;
}

.footer .footer-bottom .row .col-md-4 h6:nth-child(1) {
  color: #67696a;
  /* text-align: justify; */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 200% */
  letter-spacing: -0.4px;
  margin-top: 10px;
}
.footer .footer-bottom .row .col-md-4 h6:nth-child(2) {
  color: #3cf;
  /* text-align: justify; */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  letter-spacing: -0.4px;
}
.footer .footer-bottom .footer-line {
  /* width: 1752px; */
  width: 100%;
  height: 0.1px !important;
  background-color: #414040;
}
.footer .footer-bottom .footer-name-line {
  /* text-align: center; */
  padding: 10px;
  margin-bottom: 0px;
}
.footer .footer-bottom .footer-name-line h6 {
  color: #67696a;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  letter-spacing: -0.4px;
}


.footer .footer-bottom .row:nth-child(2) .footer-logo-stack {
  background-color: #3cf;
  display: flex;
  /* height: 30px; */
  /* width: 646px; */
}
.footer .footer-bottom .row .footer-logo-stack img {
  /* height: 80px;
  width: 80px; */

  justify-content: center;
  align-items: center;
  margin: 8px;
}
.footer .footer-bottom .after-footer-line .email-link{
  color:#33ccff !important;
}
.footer .footer-bottom .after-footer-line {
  display: flex;
  padding: 10px;

  justify-content: space-between;
  /* background-color: #373d3f; */
}
.footer .footer-bottom .after-footer-line .col-md-4 {
  /* background-color: #c0ecfb; */
  width: 41%;
}

.footer-2 {
  display: none !important;
}

/* footer 2 ends */

/* ...................................................................... */

/* media quey for tablet */
/* Styles for screen sizes between 768px and 1023px (Tablet) */
/* @media screen and (min-width: 768px) and (max-width: 1023px) {
  
  .header-nav .header-container {
    padding: 20px 40px !important;
  }

  .header-nav .header-container ul {
    margin-left: 20px;
  }

  .header-nav .header-container ul li {
    padding: 0 15px;
    font-size: 12px;
  }

  
  .banner .home .home-container {
    padding: 0 60px !important;
  }

  .banner .home .home-container .left-panel h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .banner .home .home-container .left-panel p {
    font-size: 14px;
    line-height: 25px;
  }

  .banner .home .home-container .left-panel .home-btn {
    width: 160px;
    height: 42px;
    font-size: 14px;
  }
} */
/* ..........................xxxxx...TABLET MEDIA QUERY...XXX..................................... */

/* media query for MOBILE phones................................................ */

/* #test{
  margin-top: -300px;
  width: 100%; 
  margin: 0 auto;
} */
