
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/PoppinsRegular.ttf"); font-family : "Poppins"; font-weight : 400;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/InterMedium.ttf"); font-family : "Inter"; font-weight : 500;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/PoppinsBold.ttf"); font-family : "Poppins"; font-weight : 700;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/RobotoRomanMedium.ttf"); font-family : "Roboto"; font-weight : 500;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/InterRegular.ttf"); font-family : "Inter"; font-weight : 400;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/RobotoRomanRegular.ttf"); font-family : "Roboto"; font-weight : 400;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/RobotoRegular.ttf"); font-family : "Roboto"; font-weight : 400;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/RobotoRomanLight.ttf"); font-family : "Roboto"; font-weight : 300;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/PoppinsMedium.ttf"); font-family : "Poppins"; font-weight : 500;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/PoppinsSemiBold.ttf"); font-family : "Poppins"; font-weight : 600;  }
@font-face { font-display: swap; font-style : normal; src: url("../assets/fonts/PoppinsLight.ttf"); font-family : "Poppins"; font-weight : 300;  }
