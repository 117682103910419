@media screen and (min-width: 429px) and (max-width: 743px) {
  .header-nav {
    /* background-color: #e9bcbc; */
    align-items: center !important;
    padding: 10px;
    height: 60px;
    margin-top: 10px;
  }

  .header-nav .header-container {
    display: flex;
    padding: 2% !important;
    padding: 0px !important;
  }
  .header-nav .header-container .header-section ul,
  .header-nav .header-container .header-section button {
    display: none !important;
  }
  .home .home-container {
    /* background-color: #101011 !important; */
    height: 730px !important;
    padding: 0px !important;
    display: flex;
  }

  .home .home-container .left-panel {
    float: left;
    display: block;
    font-family: Poppins;
    position: absolute;
    top: 30%;
    left: 25%;
    transform: translate(-25%, -25%);
  }
  .home .home-container .left-panel img {
    width: 178px;
    height: 70px;
  }
  .home .home-container .left-panel h2 {
    color: #373d3f;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
  }
  .home .home-container .left-panel p {
    color: #67696a;
    text-align: justify;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
  }
  .home .home-container .left-panel button {
    display: flex;
    width: 168px;
    height: 52px;
  }
  .home .home-container .left-panel .img-googleplay{
    width: 200px;
    height: 52px;
    margin: 0px;
    margin-top: 5px;
  }

  .home .home-container .right-panel-qrcode {
    display: none !important;
  }
  .home .home-container .right-panel {
    display: none !important;
  }

  /*....................... page 1 done......................... */
  .content_cat_partners_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh !important;
    }
    .content_cat_partners_1 h2{
      padding: 0px 30 !important;
      font-size: 16px;
      line-height: 30px;
      /* cursor: pointer; */
    }
    .Eprise-text-container{
      width: 80% !important;
      display: block;
      font-size:8px;
      font-family: Inter;
      color:#061561;
      padding: 0 10px !important;
      transition: transform 1s ease;
      position: relative;
      margin: auto;
    }
    .content_cat_partners_1 .texture-logo-image{
      width: 100px;
      height: 90px;
    }
    .grid-col-5{
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      margin-bottom: 20px;
      text-align: center;
      
    }
    .grid-col-4{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-align: center;
      margin-bottom: 20px;
    }
    .grid-col-3{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      text-align: center;
      margin-bottom: 20px;
    }
    .grid-col-2{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 10px 0 !important;
      height: 140px;
    }
    .grid-col-1{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 10px 0 !important;
      height: 140px;
    }
    .Eprise-text-container .etext-section p:hover{
      font-size: 12px;
    }
  /* page 2 hide */

  /* page 3 begins............ */
  .content_cat_partners_2 h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
  }
  .content_cat_partners_2 .cat_partners_2_row {
    column-gap: 20px;
  }

  .cat_partners_2_row #media-card-header-text {
    font-size: 20px;

    font-weight: 700;
    line-height: 25px;
  }
  .cat_partners_2_row #media-card {
    padding: 0px !important;
    width: 115.282px;
    border-radius: 8.021px !important;
  }
  .cat_partners_2_row #media-card-img {
    width: 45.174px !important;
    height: 50.976px !important;
  }

  .cat_partners_2_row #media-card-text {
    font-size: 12.38px;

    font-weight: 400;
    line-height: 14.851px;
  }
  .content_cat_partners_2 .partner-slider {
    height: max-content;
    width: 100%;
    background-color: #fff;
    transform: translateY(0);
    transition: transform 2s ease;
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .content_cat_partners_2 .partner-slider img {
    height: 130px;
    width: 150px;
  }

  /* page 4 hide */
  .features_1 {
    display: none !important;
  }

  /* page 5 begins */
  .features_2 #media-feature2-heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.5px;
  }
  .features_2 .slider-feature {
    width: 300px;
    height: 236px;
    /* background-color: aqua; */
    padding: 0px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-1 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-2 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-3 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-4 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-5 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-6 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-7 {
  padding: 0px;
  height: 190px;
  border-radius: 9.821px;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-8 {
  padding: 0px;
  height: 190px;
  border-radius: 9.821px;
}
.features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-9 {
  padding: 0px;
  height: 190px;
  border-radius: 9.821px;
}

  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-header-text {
    padding: 0px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    #media-slider-image {
    width: 100px;
    height: 152px;
    margin: auto;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    #media-text1-slider {
    /* background-color: #edb1d1; */
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 25.381px;
    padding: 0px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    #media-text2-slider {
    padding: 0px;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.076px; /* 118.87% */
    letter-spacing: 0.055px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .text1 {
    /* background-color: #edb1d1; */
    font-size: 10px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 15.381px;
    padding: 0px;
  }

  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .text2 {
    padding: 0px;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.076px; /* 118.87% */
    letter-spacing: 0.055px;
  }

  /* page 6 begins */
  .expanding-features {
    width: 100%;
    height: 100%;
  }
  .expanding-features h2 {
    color: #f8f8f8;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  }
   /* BLOG SECTION.......... */
   .blog {
    display: none !important;
  }

   /* footer1 section ...........................................*/

   .footer .footer-top {
    height: 370px !important;
  }
  .footer .footer-top .row {
    width: 98%;
    margin-top: 5px;
    padding: 5px;
    height: 0px !important;
  }
  .footer .footer-top .row .col-md-6 {
    margin-left: 0px;
    padding: 0px;
  }
  .footer .footer-top .row .col-md-6 h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0px;
  }
  .footer .footer-top .row .col-md-6 p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 20px; */
    margin-bottom: 0px;
  }

  .footer .footer-top .row .col-md-6 .img-googleplay {
    width: 100px;
    height: 30px;
  }
  .footer .footer-top .row .col-md-6:nth-child(2) img.img-phone-footer {
    width: 80px;
    height: 150px;
    margin-top: 12px;
  }
  .footer .footer-top .row .col-md-6:nth-child(2) img.img-laptop-footer {
    width: 240px;
    height: 160px;
    margin-top: 8px;
  }

  /* footer bottom...................... */
  .footer .footer-bottom {
    height: 360px !important;
    /* background-color: #fff; */
    /* background-color: #67696a; */
    width: 100%;
    /* height:500px; */
    padding: 8px;
    top: 0px;
    bottom: 0px;
    display: block;
  }
  .footer .footer-bottom .row:nth-child(1) {
    display: flex !important;
    justify-content: space-around;
    padding: 8px;
  }
  .footer .footer-bottom .row .col-md-2 {
    width: 30%;
    padding: 0px;
  }
  .footer .footer-bottom .row .col-md-5 {
    width: 60%;
    padding: 0px;
  }
  .footer .footer-bottom .row:nth-child(1) .col-md-2 h6 {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }
  .footer .footer-bottom .row:nth-child(1) .col-md-5 p {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    padding: 0 0px;
    margin-bottom: 0px;
  }
  .footer .footer-bottom .row:nth-child(1) .col-md-5 .row-numbers h6 {
    padding: 0px !important;
  }

  .footer .footer-bottom .row:nth-child(2) {
    display: flex !important;
    justify-content: space-evenly;
    padding: 8px;
  }
  .footer .footer-bottom .row .footer-logo-stack {
    /* margin-top: 10px;
    margin-bottom: 10px; */
    width: 50%;
    display: flex;
    justify-content: space-around;
    padding: 8px;
  }
  .footer .footer-bottom .row .col-md-4 {
    /* margin-top: 10px;
    margin-bottom: 10px; */
    width: 50%;
    padding: 8px;
  }
  .footer .footer-bottom .row .col-md-4 h6 {
    color: #3cf;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    letter-spacing: -0.4px;
  }

  .footer .footer-bottom .row:nth-child(1) .col-md-2 h5,
  .footer .footer-bottom .row:nth-child(1) .col-md-5 h5,
  .footer .footer-bottom .row:nth-child(1) .col-md-5 h6 {
    font-size: 12px;
    margin-bottom: 0px !important;
  }
  .footer .footer-bottom .row .footer-logo-stack img {
    height: 35px;
    width: 35px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    margin-right: 0px;
  }

  .footer .footer-bottom .footer-name-line {
    margin-top: 50px;
    padding: 10px;
  }
  .footer .footer-bottom .footer-name-line h6 {
    color: #67696a;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.1px;
    margin-bottom: 0px;
  }
}
