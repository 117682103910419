@media screen and (min-width: 1024px) and (max-width: 1550px) {
  .home .home-container {
    /* background-color: #101011 !important; */
    height: 690px !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
  }

  .home .home-container .left-panel {
    float: left;
    display: block;
    font-family: Poppins;
    position: absolute;
    top: 30%;
    left: 17%;
    transform: translate(-25%, -25%);
  }

  .home .home-container .right-panel {
    float: left;
    display: block;
    font-family: Poppins;
    position: absolute;
    top: 30%;
    left: 58%;
    transform: translate(-25%, -25%);
  }
  .home .home-container .right-panel .right-panel-element {
    width: 450px;
    height: 395px;
  }

  .home .home-container .left-panel img {
    height: 115px;
    width: 245px;
  }
  .home .home-container .left-panel h2 {
    width: 30px;
    font-size: 50px;
    line-height: 54px;
    font-weight: 700;
    margin-top: 20px;
  }
  .home .home-container .left-panel p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
  }

  .home .home-container .right-panel-qrcode .qrcode-container {
    width: 250px;
    height: 113;
    right: 18%;
  }

  .features_2 h2 {
    margin-top: 70px;
    font-size: 36px;
  }
  .content_cat_partners_2 h2 {
    margin-top: 80px;
    font-size: 36px;
  }
  .cat_partners_2_row #media-card {
    padding: 30px !important;
    border-radius: 30.021px !important;
    margin-top: 35px;
  }
  /* ........................................................................ */

  .content_cat_partners_1 .grid-text-container{
    width: 80%;
  }
  .content_cat_partners_1 .grid-text-container .etext-section p{
    font-size: 15px;
    color: #cfcece;
    font-weight: 500; 
  }
  .content_cat_partners_1 .grid-text-container .middle-grid-image{
    width: 270px;
    height: 460px;
    cursor: pointer;
  }
  .content_cat_partners_1 .grid-text-container .etext-section #blinkset1{
    font-size: 15px;
    font-weight: 500; 
    animation: colorChange 5s forwards infinite;
    /* transition: transform 0.3s ease;  */
  }
  .content_cat_partners_1 .grid-text-container .etext-section #blinkset2{
    font-size: 15px;
    font-weight: 500; 
    animation: colorChange 10s forwards infinite;
    
  }
  .content_cat_partners_1 .grid-text-container .etext-section #blinkset3{
    font-size: 15px;
    font-weight: 500; 
    animation: colorChange 35s forwards infinite;
    
  }
  /* .................................................................................................... */

  /* .Eprise-text-container {
    width: 80% !important;
    display: block;
    font-size: 15px;
    font-family: Inter;
    color: #061561;
    padding: 0 15px;
  }

  .content_cat_partners_1 h2 {
    font-size: 36px;
  }

  .content_cat_partners_1 .texture-logo-image {
    width: 270px;
    height: 170px;

    margin: auto;
    display: block;
    z-index: 1;
    
    display: flex;
    align-content: center;

   
  }

  .Eprise-text-container .etext-section p:hover {
    background-image: linear-gradient(
      -225deg,
      #429bd8 0%,
      #5fc3ef 29%,
      #a6ccd6 67%,
      #f7d5b5 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1s linear infinite;
    display: inline-block;
    font-size: 15px;
  } */

  /* feature 1 */
  .features_1 h2 {
    margin-top: 80px;
  }
  .features_1 .left-girl {
    position: absolute;
    top: 68%;
    right: 35%;
    transform: translate(50%, -25%);
  }
  .features_1 .slide-in-leftanimation {
    position: absolute;

    animation-name: slideInRight;
    animation-duration: 5.2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  @keyframes slideInRight {
    0% {
      right: -100%; /* Start the image off the screen to the left */
    }
    100% {
      right: 35%; /* Slide the image to the original position (0% means end of the animation) */
    }
  }
  .features_1 .left-girl {
    height: 292px;
    width: 442px;
  }

  .features_1 .right-girl {
    position: absolute;
    top: 55%;
    left: 20%;
    transform: translate(-50%, -25%);
  }
  .features_1 .slide-in-rightanimation {
    position: absolute;

    animation-name: slideInLeft;
    animation-duration: 5.2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  @keyframes slideInLeft {
    0% {
      left: -100%; /* Start the image off the screen to the left */
    }
    100% {
      left: 20%; /* Slide the image to the original position (0% means end of the animation) */
    }
  }
  .features_1 .right-girl {
    height: 282px;
    width: 297px;
  }

  /* feature 1 ends */

  .features_1 h2 {
    padding: 70px 20px;
    font-size: 36px;
  }

  .features_2 .slider-feature {
    width: 90%;

    padding: 5px;
    margin-bottom: 0px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-1 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-2 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-3 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-4 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-5 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-6 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-7 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-8 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-9 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }

  /* images slide code... */
  /* .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid .col-6{
      background-color: black;

    }

  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid img{
      background-color: #061561;

    } */
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-1
    img {
    width: 270px;
    height: 290px;
    margin: auto;
  }

  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-2
    img {
    width: 280px;
    height: 290px;
    margin: auto;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-3
    img {
    width: 275px;
    height: 290px;
    margin: auto;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-4
    img {
    width: 265px;
    height: 290px;
    margin: auto;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-5
    img {
    width: 245px;
    height: 290px;
    margin: auto;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-6
    img {
    width: 235px;
    height: 290px;
    margin: auto;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-7
    img {
    width: 235px;
    height: 290px;
    margin: auto;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-8
    img {
    width: 245px;
    height: 300px;
    margin: auto;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-9
    img {
    width: 245px;
    height: 300px;
    margin: auto;
  }

  .expanding-features {
    width: 100%;
    height: 100%;
  }
  .expanding-features h2 {
    padding: 70px 40px;
  }

  .footer .footer-top {
    height: 500px !important;
  }
  .footer .footer-top .row {
    width: 98%;
    margin-top: 10px;
    height: 0px !important;
  }
  .footer .footer-top .row .col-md-6 {
    margin-left: 0px;
    padding: 15px;
  }
  .footer .footer-top .row .col-md-6 h2 {
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 45.5px;
    color: #213339;
  }
  .footer .footer-top .row .col-md-6 p {
    color: #67696a;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.5px;
  }
  .footer .footer-top .row .col-md-6 .img-googleplay {
    width: 180px;
    height: 54px;
  }
  .footer .footer-top .row .col-md-6:nth-child(2) img.img-phone-footer {
    width: 95px;
    height: 200px;
    margin-top: 12px;
  }
  .footer .footer-top .row .col-md-6:nth-child(2) img.img-laptop-footer {
    width: 280px;
    height: 220px;
    margin-top: 8px;
  }
  .footer .footer-bottom {
    height: 500px !important;
    background-color: #fff;
    /* align-items: center;
    justify-content: center; */
    width: 100%;
    padding: 15px;
    display: block;
  }
  .footer .footer-bottom .row:nth-child(1) {
    display: flex !important;
  }
  .footer .footer-bottom .row .col-md-2 {
    width: 50%;
  }
  .footer .footer-bottom .row .col-md-5 {
    width: 50%;
  }
  .footer .footer-bottom .row:nth-child(2) {
    display: flex !important;
  }
  .footer .footer-bottom .row .footer-logo-stack {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50%;
  }
  .footer .footer-bottom .row .col-md-4 {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50%;
  }
  .footer .footer-bottom .footer-name-line {
    margin-top: 10px;
  }
}
