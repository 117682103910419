@media screen and (min-width: 744px) and (max-width: 1023px) {
  .header-nav {
    /* background-color: #e9bcbc; */
    align-items: center !important;
    padding: 10px;
    height: 70px;
    margin-top: 10px;
  }
  .header-nav .header-container {
    display: flex;
    padding: 7% !important;
    padding: 0px !important;
  }
  .header-nav .header-container .header-section {
    align-items: center;
  }

  .header-nav .header-container ul {
    margin-left: 0px;
    text-align: center;
    align-items: center;
  }

  /* .............HEADER COMPLETED............... */
  .home .home-container {
    /* background-color: #101011 !important; */
    height: 1130px !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
  }

  .home .home-container .left-panel {
    float: left;
    display: block;
    font-family: Poppins;
    position: absolute;
    top: 30%;
    left: 17%;
    transform: translate(-25%, -25%);
  }

  .home .home-container .right-panel {
    /* Add tablet-specific styles for the home-container here */
    float: left;
    display: block;
    font-family: Poppins;
    position: absolute;
    top: 27%;
    left: 50%;
    transform: translate(-25%, -25%);
  }
  .home .home-container .right-panel .right-panel-element {
    width: 420px;
    height: 360px;
  }
  .home .home-container .left-panel img {
    height: 115px;
    width: 255px;
  }
  .home .home-container .left-panel h2 {
    width: 30px;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    margin-top: 20px;
  }
  .home .home-container .left-panel p {
    /* width:360px; */
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
  }
  .home .home-container .right-panel-qrcode .qrcode-container  {
    width: 250px;
    margin-bottom: 20px;
  }
  

  /* home completed.............. */
  .content_cat_partners_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh !important;
  }
  .content_cat_partners_1 .grid-text-container{
    width: 80%;
  }
  .content_cat_partners_1 .grid-text-container .etext-section p{
    font-size: 10px;
    color: #cfcece;
    font-weight: 500; 
  }
  .content_cat_partners_1 .grid-text-container .middle-grid-image{
    width: 180px;
    height: 340px;
    cursor: pointer;
  }
  .content_cat_partners_1 .grid-text-container .etext-section #blinkset1{
    font-size: 10px;
    font-weight: 500; 
    animation: colorChange 5s forwards infinite;
    /* transition: transform 0.3s ease;  */
  }
  .content_cat_partners_1 .grid-text-container .etext-section #blinkset2{
    font-size: 10px;
    font-weight: 500; 
    animation: colorChange 10s forwards infinite;
    
  }
  .content_cat_partners_1 .grid-text-container .etext-section #blinkset3{
    font-size: 10px;
    font-weight: 500; 
    animation: colorChange 35s forwards infinite;
    
  }
  /* .content_cat_partners_1 .texture-heading-text {
    margin: auto;
    text-align: center;
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
   
  }

  .content_cat_partners_1 h2 {
    line-height: 70px;
    font-size: 36px;
  }

  .Eprise-text-container {
    width: 80% !important;
    display: block;
    font-size: 12px;
    font-family: Inter;
    color: #061561;
    padding: 0 15px;
    
  }
  .content_cat_partners_1 .texture-logo-image {
    width: 270px;
    height: 150px;

    margin: auto;
    display: block;
    z-index: 1;
  
    display: flex;
    align-content: center;

   
  }

  .Eprise-text-container .etext-section p:hover {
    background-image: linear-gradient(
      -225deg,
      #429bd8 0%,
      #5fc3ef 29%,
      #a6ccd6 67%,
      #f7d5b5 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1s linear infinite;
    display: inline-block;
    font-size: 12px;
  } */

  /* page 3 begins */
  .content_cat_partners_2 h2 {
    margin-top: 180px;
    font-size: 36px;
  }
  .cat_partners_2_row #media-card {
    padding: 30px !important;
    border-radius: 30.021px !important;
    margin-top: 35px;
  }

  .content_cat_partners_2 .partner-slider {
    height: 220px;
    width: 100%;
    background-color: #fff;
    transform: translateY(0);
    transition: transform 2s ease;
    margin-bottom: 90px;
  }
  .features_1 h2 {
    margin-top: 80px;
    padding: 10px;
    font-size: 36px;
  }
  .features_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh !important;
  }

  .features_1 .left-girl {
    position: absolute;
    top: 63%;
    right: 25%;
    transform: translate(50%, -30%);
  }
  .features_1 .left-girl {
    height: 400px;
    width: 490px;
  }
  .features_1 .right-girl {
    position: absolute;
    top: 55%;
    left: 10%;
    transform: translate(-30%, 3%);
  }
  .features_1 .right-girl {
    height: 370px;
    width: 460px;
  }

  /* feature 2 slider section media query */

  .features_2 h2 {
    padding: 20px;
    margin-top: 160px;
    font-size: 36px;
  }

  .features_2 .slider-feature {
    width: 90%;
    /* height:250px; */
    /* height: max-content; */
    /* background-color: aqua; */
    padding: 5px;
    margin-bottom: 50px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-1 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }

  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-2 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-3 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-4 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-5 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-6 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-7 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-8 {
    padding: 0px;
    height: 395px;
    border-radius: 9.821px;
  }
  .features_2
  .slider-feature
  .custom-slider
  .carousel-item
  .container-fluid
  .slide-9 {
  padding: 0px;
  height: 395px;
  border-radius: 9.821px;
}
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-header-text {
    padding: 0px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    #media-slider-image {
    width: 250px;
    height: 252px;
    margin: auto;
  }

  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    #media-text1-slider {
    /* background-color: #edb1d1; */
    font-size: 35px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 50.5px;
    padding: 0px;
    margin-left: 0px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    #media-text2-slider {
    padding: 0px;
    /* font-size: 16px; */
    /* font-style: normal;
    font-weight: 400;
    line-height: 20.076px; 
    letter-spacing: 0.055px; */
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .text1 {
    /* font-size: 10px !important; */
    font-style: normal;
    font-weight: 500;
    line-height: 15.381px;
    padding: 0px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .text2 {
    width: 90%;
    padding: 0px;
    /* font-size: 11px; */
    font-style: normal;
    font-weight: 400;
    /* line-height: 13.076px;  */
    letter-spacing: 0.055px;
  }

  .blog {
    display: none !important;
  }

  /* .blog .blog-container {
      width: 100%;
      padding: 20px;
    
      background-color: #929dc2;
    }

    .blog .blog-top {
      display: flex;
      align-items: center;
      justify-content:center;
      height: 150px;
      padding: 20px;
      padding-right: 50px;
      max-width:80%;
      background-color: #c0fbd9;
    }

    .blog .blog-top h2{
      font-size: 30px;
      padding: 0px;
      line-height: 40px;
     
    }
    */

  /* FAQ SECTION */

  .faq .faq-h2 {
    padding-top: 0px;
    margin-top: 10px;
  }

  .faq .faq-body {
    padding: 0px !important;
    width: 80%;
  }

  /* download page.......... */
  .download-page {
    background-color: chocolate !important;
    display: block !important;
  }

  .download-page .download-header {
    width: 100%;
    height: 100px;
  }
  .download-page .download-header p {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    /* padding: 0px; */
  }

  .download-page .download-content {
    position: absolute;
    padding: 8px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
  }
  .download-page .download-content .card {
    width: 55%;
    height: 260px;
    margin-top: 5px;
  }
  .download-page .download-content .card img {
    height: 190px !important;
  }
  .download-page .download-content .card img,
  .download-page .download-content .card .card-body {
    width: 100%;
    height: auto;
    padding: 8px;
    display: flex;
  }

  .download-page .download-footer {
    background-color: aquamarine !important;
    width: 100%;
    margin-top: 10px;
    padding: 0px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .download-page .download-footer p {
    font-size: 18px;
    line-height: 21px;
  }

  /* footer alignment */

  .footer .footer-top {
    height: 690px !important;
  }

  .footer .footer-top .row {
    width: 98%;
    margin-top: 40px;
    height: 0px !important;
  }
  .footer .footer-top .row .col-md-6 {
    margin-left: 0px;
    padding: 0px;
  }
  .footer .footer-top .row .col-md-6 h2 {
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 45.5px;
    color: #213339;
  }
  .footer .footer-top .row .col-md-6 p {
    color: #67696a;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.5px;
  }
  .footer .footer-top .row .col-md-6 .img-googleplay {
    width: 180px;
    height: 54px;
  }
  .footer .footer-top .row .col-md-6:nth-child(2) img.img-phone-footer {
    width: 95px;
    height: 180px;
    margin-top: 20px;
  }
  .footer .footer-top .row .col-md-6:nth-child(2) img.img-laptop-footer {
    width: 300px;
    height: 200px;
    margin-top: 8px;
  }
  .footer .footer-bottom {
    height: 530px !important;
    background-color: #fff;
    /* align-items: center;
    justify-content: center; */
    width: 100%;
    padding: 15px;
    display: block;
  }
  .footer .footer-bottom .row:nth-child(1) {
    display: flex !important;
  }
  .footer .footer-bottom .row:nth-child(1) .col-md-6 p {
    line-height: 18px;
  }
  .footer .footer-bottom .row:nth-child(1) .col-md-2 h5,
  .footer .footer-bottom .row:nth-child(1) .col-md-5 h5,
  .footer .footer-bottom .row:nth-child(1) .col-md-5 h6 {
    line-height: 10px;
  }
  .footer .footer-bottom .row:nth-child(1) .col-md-2 h6 {
    line-height: 22px;
  }

  .footer .footer-bottom .row .col-md-2 {
    width: 50%;
  }
  .footer .footer-bottom .row .col-md-5 {
    width: 50%;
  }
  .footer .footer-bottom .row:nth-child(2) {
    display: flex !important;
  }
  .footer .footer-bottom .row .col-md-4 h6:nth-child(1) {
    line-height: 22px;
  }
  .footer .footer-bottom .row .col-md-4 h6:nth-child(2) {
    line-height: 0px;
  }

  .footer .footer-bottom .row .footer-logo-stack {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 50%;
  }
  .footer .footer-bottom .row .col-md-4 {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 50%;
  }
  .footer .footer-bottom .footer-name-line {
    margin-top: 8px;
  }
  .footer .footer-bottom .footer-name-line h6 {
    line-height: 10px;
  }
}
