@media screen and (min-width: 320px) and (max-width: 428px) {
  .header-nav {
    height: 60px;
    /* background-color: #e9bcbc; */
    align-items: center !important;
    display: flex;
  }
  .header-nav .header-container {
    display: flex;
    padding: 5% !important;
  }
  .header-nav .header-container .header-section ul,
  .header-nav .header-container .header-section button {
    display: none !important;
  }
  /* header done...................................................... */
  .home .home-container {
    /* background-color: #101011 !important; */
    height: 490px !important;
    padding: 0px !important;
    display: flex;
  }

  .home .home-container .left-panel {
    float: left;
    display: block;
    font-family: Poppins;
    position: absolute;
    top: 30%;
    left: 25%;
    transform: translate(-25%, -25%);
  }

  .home .home-container .left-panel img {
    width: 148px;
    height: 63px;
  }
  .home .home-container .left-panel h2 {
    color: #373d3f;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
  }
  .home .home-container .left-panel p {
    color: #67696a;
    text-align: justify;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .home .home-container .left-panel button {
    display: flex;
    width: 168px;
    height: 52px;
  }
  .home .home-container .left-panel .img-googleplay{
    display: flex;
    width: 200px;
    height: 52px;
    margin: 0px;
    margin-top: 5px;
  }

  /* .home .home-container .left-panel h2,
    .home .home-container .left-panel p {
      display: none !important;
    } */
  .home .home-container .right-panel-qrcode {
    display: none !important;
  }
  .home .home-container .right-panel {
    display: none !important;
  }

  /* home done........................................... */

  /* page 2  */

  .content_cat_partners_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh !important;
    display: none !important;
  }
  .content_cat_partners_1 .grid-text-container{
    width: 80% !important;
  }
  .content_cat_partners_1 .grid-text-container .etext-section p{
    font-size: 8px;
    color: #cfcece;
    font-weight: 500; 
  }
  .content_cat_partners_1 .grid-text-container .middle-grid-image{
    width: 80px;
    height: 140px;
    cursor: pointer;
  }
  .content_cat_partners_1 .grid-text-container .etext-section #blinkset1{
    font-size: 8px;
    font-weight: 500; 
    animation: colorChange 5s forwards infinite;
    /* transition: transform 0.3s ease;  */
  }
  .content_cat_partners_1 .grid-text-container .etext-section #blinkset2{
    font-size: 8px;
    font-weight: 500; 
    animation: colorChange 10s forwards infinite;
    
  }
  .content_cat_partners_1 .grid-text-container .etext-section #blinkset3{
    font-size: 8px;
    font-weight: 500; 
    animation: colorChange 35s forwards infinite;
    
  }
  .grid-col-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    margin-bottom: 10px;
  }
  
  .grid-col-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    margin-bottom: 10px;
   }
  
  /* .content_cat_partners_1 h2 {
    padding: 0px 10px !important;
    font-size: 16px;
    line-height: 30px;
    width: 90%;
    
  }
  .Eprise-text-container {
    width: 85% !important;
    display: block;
    font-size: 8px;
    font-family: Inter;
    color: #061561;
    padding: 0 10px !important;
    transition: transform 1s ease;
    position: relative;
    margin: auto;
  }
  .content_cat_partners_1 .texture-logo-image {
    width: 80px;
    height: 80px;
  }
  .grid-col-5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 20px;
    text-align: center;
  }
  .grid-col-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    margin-bottom: 20px;
  }
  .grid-col-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    margin-bottom: 10px;
  }
  .grid-col-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 5px 0 !important;
    height: 140px;
  }
  .grid-col-1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 5px 0 !important;
    height: 140px;
  }
  .Eprise-text-container .etext-section p:hover {
    font-size: 12px;
  } */

  /* page 3 begins........ */

  .content_cat_partners_2 h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
  }
  .content_cat_partners_2 .cat_partners_2_row {
    column-gap: 10px;
  }

  .cat_partners_2_row #media-card-header-text {
    font-size: 20px;

    font-weight: 700;
    line-height: 25px;
  }
  .cat_partners_2_row #media-card {
    padding: 0px !important;
    width: 90.282px;
    border-radius: 8.021px !important;
  }
  .cat_partners_2_row #media-card-img {
    width: 35.174px !important;
    height: 35.976px !important;
  }

  .cat_partners_2_row #media-card-text {
    font-size: 9.38px;

    font-weight: 400;
    line-height: 14.851px;
  }
  .content_cat_partners_2 .partner-slider {
    height: max-content;
    width: 100%;
    background-color: #fff;
    transform: translateY(0);
    transition: transform 2s ease;
    margin-bottom: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .content_cat_partners_2 .partner-slider img {
    height: 130px;
    width: 150px;
  }
  .features_1 {
    display: none !important;
  }

  .features_2 #media-feature2-heading {
    /* pending section */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.5px;
  }

  /* feature 2 slider section media query */

  .features_2 .slider-feature {
    width: 300px;
    height: 236px;
    /* background-color: aqua; */
    padding: 0px;
    margin-bottom: 50px;
  }

  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .row
    .col-6:nth-child(1) {
    display: none !important;
  }

  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-1 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-2 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-3 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-4 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-5 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-6 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-7 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-8 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-9 {
    padding: 0px;
    height: 190px;
    border-radius: 9.821px;
  }

  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .slide-header-text {
    padding: 0px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    #media-slider-image {
    width: 100px !important;
    height: 152px !important;
    margin: auto;
    /* display: none !important; */
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    #media-text1-slider {
    /* background-color: #edb1d1; */
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 25.381px;
    padding: 0px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    #media-text2-slider {
    padding: 0px;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.076px; /* 118.87% */
    letter-spacing: 0.055px;
    margin-right: 5px;
  }
  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .text1 {
    /* background-color: #edb1d1; */
    font-size: 10px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 15.381px;
    padding: 0px;
  }

  .features_2
    .slider-feature
    .custom-slider
    .carousel-item
    .container-fluid
    .text2 {
    padding: 0px;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.076px; /* 118.87% */
    letter-spacing: 0.055px;
  }

  /* feature 2 ends................. */
  /* expanding feature page.... */

  .expanding-features {
    width: 100%;
    height: 100%;
  }
  .expanding-features h2 {
    color: #f8f8f8;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  }

  /* BLOG SECTION.......... */
  .blog {
    display: none !important;
  }

  /* FAQ SECTION....... */

  .faq .faq-h2 {
    display: block;
    text-align: left;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 90.5px;
    padding: 0px !important;
  }
  .faq .faq-body {
    padding: 0px !important;
  }

  /* faq -ends */

  /* dowmload sections */

  .download-page {
    padding: 0px;
  }
  .download-page .download-header {
    width: 100%;
    height: 67px;
    padding: 0px;
  }

  .download-page .download-header .download-marquee {
    width: 275px;
    padding: 0px;
  }
  .download-page .download-header p {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    padding: 0px;
  }

  .download-page .download-content {
    position: absolute;
    padding: 8px;
    height: auto;

    /* height: 300px; */
    display: flex;
    overflow: hidden;
    width: 100%;
  }
  .download-page .download-content .card {
    position: relative;
    padding: 0px;
    width: 100%;
    margin: 0.5px;
    height: 150px;
    /* height: 280px; */
  }
  .download-page .download-content .card img,
  .download-page .download-content .card .card-body {
    width: 100%;
    height: auto;
    padding: 9px;
    display: block;
    align-items: center;

    /* width: 295px;
      height: 219px;
      padding: 9px; */
  }
  .download-page .download-content .card .img-empty {
    width: 100%;
    height: 150px;
  }
  .download-page .download-content .card .card-body p {
    font-size: 10px;
  }
  .download-page .download-content .card .card-body .btn {
    width: 50px;
    height: 25px;
    font-size: 8px;
  }

  .download-page .download-footer {
    position: absolute;
    height: 200px;

    bottom: 10px;
    padding: 9px;
  }

  .download-page .download-footer p {
    font-size: 12px;
    line-height: 21.5px;
  }

  /* footer1 section ...........................................*/

  .footer .footer-top {
    height: 290px !important;
    padding: 0px 5px;
  }
  .footer .footer-top .row {
    width: 98%;
    margin-top: 5px;
    padding: 5px;
    height: 0px !important;
  }
  .footer .footer-top .row .col-md-6 {
    margin-left: 0px;
    padding: 0px;
  }
  .footer .footer-top .row .col-md-6 h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0px;
  }
  .footer .footer-top .row .col-md-6 p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0px;
  }

  .footer .footer-top .row .col-md-6 .img-googleplay {
    width: 100px;
    height: 30px;
  }
  .footer .footer-top .row .col-md-6:nth-child(2) img.img-phone-footer {
    width: 80px;
    height: 150px;
    margin-top: 12px;
  }
  .footer .footer-top .row .col-md-6:nth-child(2) img.img-laptop-footer {
    width: 240px;
    height: 160px;
    margin-top: 8px;
  }

  /* footer bottom...................... */
  .footer .footer-bottom {
    height: 270px !important;
    /* background-color: #fff; */
    /* background-color: #67696a; */
    width: 100%;
    /* height:500px; */
    padding: 5px;
    top: 0px;
    bottom: 0px;
    display: block;
  }
  .footer .footer-bottom .row:nth-child(1) {
    display: flex !important;
    justify-content: space-around;
    padding: 8px;
  }
  .footer .footer-bottom .row .col-md-2 {
    width: 30%;
    padding: 0px;
  }
  .footer .footer-bottom .row .col-md-5 {
    width: 60%;
    padding: 0px;
  }
  .footer .footer-bottom .row:nth-child(1) .col-md-2 h6 {
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
  }
  .footer .footer-bottom .row:nth-child(1) .col-md-5 p {
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    padding: 0 5px;
    margin-bottom: 0px;
  }
  .footer .footer-bottom .row:nth-child(1) .col-md-5 .row-numbers h6 {
    padding: 0px !important;
  }

  .footer .footer-bottom .row:nth-child(1) .col-md-5 .row-numbers img,
  .footer .footer-bottom .row:nth-child(1) .col-md-5 .row-p-tage img {
    height: 30px;
    width: 30px;
    padding: 5px;
    margin-right: 10px;
  }

  .footer .footer-bottom .row:nth-child(2) {
    display: flex !important;
    justify-content: space-evenly;
    padding: 8px;
  }
  .footer .footer-bottom .row .footer-logo-stack {
    /* margin-top: 10px;
    margin-bottom: 10px; */
    width: 50%;
    display: flex;
    justify-content: space-around;
    padding: 8px;
  }
  .footer .footer-bottom .row .col-md-4 {
    /* margin-top: 10px;
    margin-bottom: 10px; */
    width: 50%;
    padding: 8px;
  }
  .footer .footer-bottom .row .col-md-4 h6 {
    color: #3cf;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 15px !important;
    letter-spacing: -0.4px;
  }

  .footer .footer-bottom .row:nth-child(1) .col-md-2 h5,
  .footer .footer-bottom .row:nth-child(1) .col-md-5 h5,
  .footer .footer-bottom .row:nth-child(1) .col-md-5 h6 {
    font-size: 9px;
    margin-bottom: 0px !important;
  }
  .footer .footer-bottom .row .footer-logo-stack img {
    height: 30px;
    width: 30px;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 2px;
    margin-right: 0px;
  }

  .footer .footer-bottom .footer-name-line {
    margin-top: 0px;
    padding: 0px;
  }
  .footer .footer-bottom .footer-name-line h6 {
    color: #67696a;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.4px;
    margin-bottom: 0px;
  }

  /* footer bottom ends....................................... */
}

/* .footer-2 .footer-bottom {
    padding: 0px;
    top: 0px;
    bottom: 0px;
  }
  .footer-2 .footer-bottom .row {
    padding: 0px;
  }
  .footer-2 .footer-bottom .col-md-6 {
    padding: 0px;
  }
  .footer-2 .footer-bottom .row:nth-child(1) .col-md-6 p {
    padding: 0 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
  .footer-2 .footer-bottom .row:nth-child(1) .col-md-1 h6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.5px;
  }
  .footer-2 .footer-bottom .row:nth-child(1) .col-md-5 h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  .footer-2 .footer-bottom .row:nth-child(1) .col-md-5 p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding: 0 20px;
  }
  .footer-2 .footer-bottom .row .col-md-5 img {
    background-color: #000;
    display: none !important;
  }
  .footer-2 .footer-bottom .row .col-md-4 h6 {
    display: none !important;
  }
  .footer-2 .footer-bottom .footer-name-line {
    padding: 0px;
  } */
/* .footer {
      margin-top: 5px;
    } */
/* .footer .footer-top .row {
      display: block;
    }
    .footer .footer-top .row .col-md-6:nth-child(1) {
      position: relative;
      
      
      padding: 10px;
    }
    .footer .footer-top .row .col-md-6:nth-child(2) {
      
      bottom: 10px;
      width: 321px;
      height: 229px;
      padding: 10px;
    }
  
    .footer .footer-top {
      width: 390px;
      height: 497px;
    }
    .footer .footer-top .row .col-md-6 h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
    .footer .footer-top .row .col-md-6 p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .footer .footer-top .row .col-md-6 .img-googleplay {
      width: 94px;
      height: 28px;
    }
    .footer .footer-top .row .col-md-6:nth-child(2) {
      display: flex;
      position: absolute;
    }
    .footer .footer-top .row .col-md-6:nth-child(2) img.img-phone-footer {
      width: 90px;
      height: 166px;
      position: relative;
      top: 30px;
      z-index: 1;
      
    }
    .footer .footer-top .row .col-md-6:nth-child(2) img.img-laptop-footer {
      width: 321px;
      height: 229px;
      position: absolute;
      bottom: 10px;
    } */

/* .footer-2 {
    flex-direction: row;
    
  } */
