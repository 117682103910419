.demo-page {
	align-items: center;
	justify-content: center;
	overflow-y: hidden !important;
  }
  
  .demo-page .demo-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	height: 92vh !important;
	overflow-y: hidden !important;
  }
  
  .demo-page .demo-container .demo-section {
	padding: 30px 50px;
	align-items: center;
	justify-content: center;
  }
  
  .demo-page .demo-container .demo-section .demo-section-content {
	margin: 20% auto;
  }
  
  .demo-page .demo-container .demo-section:nth-child(1) {
	background-color: #dff7ff;
  }
  
  .demo-page .demo-container .demo-section:nth-child(1) img {
	width: 230px;
	height: 100px;
  }
  
  .demo-page .demo-container .demo-section:nth-child(1) h2 {
	font-size: 42px;
	line-height: 70px;
	font-weight: 700;
	margin: 20px;
  }
  
  .demo-page .demo-container .demo-section:nth-child(1) p {
	font-size: 16px;
	line-height: 29px;
	font-weight: 400;
	font-family: "Poppins", sans-serif;
	text-align: justify;
	margin: 20px;
	width: 100%;
  }
  
  .demo-page .demo-container .demo-section:nth-child(1) .demo-contacts {
	display: flex;
	flex-direction: column;
	width: 70%;
  }
  
  .demo-page .demo-container .demo-section:nth-child(1) .demo-contancts-row {
	display: flex;
	margin-bottom: 10px;
  }
  
  .demo-page .demo-container .demo-section:nth-child(1) .demo-contancts-row img {
	height: 20px;
	width: 20px;
	margin: auto 20px;
  }
  
  .demo-page .demo-container .demo-section:nth-child(1) .demo-contancts-row p {
	margin: 0;
  }
  
  .demo-page .demo-container .demo-section:nth-child(1) .footer-logo {
	display: flex;
	margin: 20px 10px;
  }
  
  .demo-page .demo-container .demo-section:nth-child(1) .footer-logo a {
	margin: 0 10px;
  }
  
  .demo-page .demo-container .demo-section:nth-child(1) .footer-logo img {
	height: 44px;
	width: 44px;
  }
  
  /* Right Section (Form) Styling */

  
  .demo-page .demo-container .demo-section:nth-child(2) .demo-form form {
	width: 80%;
	margin: 0 auto;
  }
  
  .demo-page .demo-container .demo-section:nth-child(2) .demo-form input,
  .demo-page .demo-container .demo-section:nth-child(2) .demo-form textarea {
	margin-bottom: 20px;
	padding: 10px;
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 5px;
  }
  
  .demo-page .demo-container .demo-section:nth-child(2) .demo-form .compact {
	margin: 10px auto;
  }
  
  .demo-page .demo-container .demo-section:nth-child(2) .demo-form .demo-btn {
	width: 100%;
	padding: 10px;
	background-color: #4c8bf5;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
  }
  
  .demo-page .demo-container .demo-section:nth-child(2) .demo-form .demo-btn:disabled {
	background-color: #ccc;
  }
  

  .demo-page .demo-container .demo-section:nth-child(2) .demo-form form {
	width: 90%; /* Wider form container */
	max-width: 500px; /* Limit the max width for larger screens */
	margin: 0 auto;
  }
  
  .demo-page .demo-container .demo-section:nth-child(2) .demo-form input,
  .demo-page .demo-container .demo-section:nth-child(2) .demo-form textarea {
	margin-bottom: 20px;
	padding: 10px;
	width: 100%; /* Full width within the form */
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 16px;
  }
  
  .demo-page .demo-container .demo-section:nth-child(2) .demo-form .demo-btn {
	width: 100%; /* Full width for the button */
	padding: 10px;
	background-color: #4c8bf5;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
  }
  