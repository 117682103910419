.t-c-container{
    background-color:#F7F9FB;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    text-align: center;
    justify-content: center;
    margin: auto;    
}
.t-c-container .t-c-content{
    margin-top: 30px;
    margin-bottom: 50px;   
}
.t-c-content h1{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;    
}
.t-c-content .horizontal-line{
    margin-top: 20px;
    width: 100%;
    height: 1.5px !important;
    background-color: #c2b6b6;
}
.t-c-content h2{
    text-align: justify;     
}
.t-c-content p{
    margin-top: 30px;
    text-align: justify;
}
.t-c-content ol{
    text-align: justify;
}
.t-c-content span{
    font-weight: bold; 
}
